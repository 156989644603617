<template>
	<div class="bigBox">
		<h2>行业分类管理</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.name" prefix-icon="Search" placeholder="行业名称"
								class="accountPut"></el-input>
							<!-- <el-select v-model="form.region1" placeholder="审核状态" class="electIpt">
								<el-option label="区域一" value="shanghai"></el-option>
								<el-option label="区域二" value="beijing"></el-option>
							</el-select> -->
							<el-button type="primary" icon="Search" class="searchs" @click="searchs">
								搜索</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
						<div class="row-me row-center addDiv" v-if="this.powerListName.includes('新增按钮')">
							<img src="../../assets/img/icon_xz.png" class="iconImg" />&nbsp;
							<span class="youzhi" @click="handleClickEdit({})">新建行业分类</span>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="classTabArr" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="name" label="行业名称" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="创建时间" align="center">
					</el-table-column>
					<el-table-column label="操作" align="left" width="140">
						<template #default="scope">
							<el-button type="text" size="small" v-if="this.powerListName.includes('编辑按钮')"
								@click="handleClickEdit(scope.row)">编辑
							</el-button>
							<el-button type="text" size="small" v-if="this.powerListName.includes('删除按钮')"
								@click="deleteClick(scope.row)">删除
							</el-button>
							<!-- <el-popconfirm title="确定删除吗?" @confirm="handleClickDel(scope.row)"><template #reference>
									<el-button type="text" size="small">删除</el-button>
								</template>
							</el-popconfirm> -->
						</template>
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="classTabArr.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
		<!-- 新增行业分类 -->
		<el-dialog v-model="dialogaddArrange" :title="dialogTitle" width="30%" draggable>
			<el-form :model="forms" label-position="right">
				<el-form-item label="行业名称">
					<el-input v-model="forms.name" placeholder="请输入" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogaddArrange = false" class="width100height40 margin-right10">取消</el-button>
					<el-button type="primary" @click="preserveBtn">
						保存
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		tradeListInfo,
		tradeInfoAdd,
		tradeInfoDel
	} from "@/api/customerList/customerList.js"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				form: {
					name: "",
				},
				forms: {
					name: "",
				},
				classTabArr: [],
				dialogaddArrange: false,
				classTabPage: "",
				pageNo: 1,
				total: 0,
				powerListName: [], //权限按钮
			}
		},
		mounted() {
			var list = JSON.parse(sessionStorage.getItem('menuArrayRegulate'));
			console.log(list, 'listlist');
			var nameList = [];

			for (var i = 0; i < list.length; i++) {
				if (list[i].name == "客户管理") {
					for (var j = 0; j < list[i].children.length; j++) {

						if (list[i].children[j].name == "行业分类管理") {
							nameList = list[i].children[j].children;
						}
					}
				}
			}

			if (nameList != undefined) {
				for (var i = 0; i < nameList.length; i++) {
					this.powerListName.push(nameList[i].name)
				}
			}

			console.log(this.powerListName, '最后按钮')
			this.getClassTabList()
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getClassTabList()
			},
			// 搜索
			searchs() {
				this.getClassTabList()
			},
			// 重置
			resetting() {
				this.pageNo = 1
				this.form.name = ''
				this.getClassTabList()
			},
			async getClassTabList() {
				const res = await tradeListInfo({
					keyword: this.form.name,
					page: this.pageNo,
				})
				this.classTabArr = res.data.listInfo
				this.total = res.data.allListNumber
			},
			//表格新增编辑
			handleClickEdit(row) {
				if (row.id) {
					this.dialogTitle = '编辑行业分类'
					this.id = row.id
					this.forms = JSON.parse(JSON.stringify(row))
				} else {
					this.dialogTitle = '新增行业分类'
					this.forms.name = ""
					this.forms.id = ""
				}
				this.dialogaddArrange = true
			},
			// 新增编辑保存按钮
			async preserveBtn() {
				if (this.id == '') {
					const res = await tradeInfoAdd({
						name: this.forms.name
					})
				} else {
					const res1 = await tradeInfoAdd({
						id: this.id,
						...this.forms
					})
				}
				this.dialogaddArrange = false
				this.forms.name = ""
				this.getClassTabList()
				console.log(this.classTabArr, "66666")
			},
			deleteClick(row) {
				console.log(row, 'rowrow')
				this.$confirm("确定删除吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$post('/admin/tradeInfoDel', {
						id: row.id
					}).then((res) => {
						if (res) {
							this.$message.success('删除成功')
							this.getClassTabList()
						}
					}).catch((error) => {
						console.log(error)
					})
				}).catch(() => {});
			},
			// 表格删除
			async handleClickDel(row) {
				const res = await tradeInfoDel({
					id: row.id
				})
				// ElMessage({
				// 	message: '删除成功',
				// 	type: 'success',
				// })

			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.accountPut {
		margin-right: 20px;

		/deep/.el-input__wrapper {
			width: 360px;
			height: 40px;
		}
	}

	.addDiv {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 10px;
		cursor: pointer;

		.iconImg {
			width: 26px;
			height: 26px;
		}

		.youzhi {
			font-size: 18px;
		}
	}

	.agains {
		padding: 0 31px !important;
		margin-left: 10px !important;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}

	/deep/.el-button+.el-button {
		margin-left: 0px;
	}
</style>